import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Modal, Row } from "react-bootstrap";
import { MdOutlineArrowOutward } from "react-icons/md";
import { Subscribe } from "components";
import { useMediaQuery } from "react-responsive";
import * as actions from "reducer/utilitySlice";
import { t } from "i18next"
import { IoLogoWhatsapp } from "react-icons/io";
import PromotionModal from "./PromotionModal";
import { useNavigate } from "react-router-dom";

const Promotions = () => {

  const navigate = useNavigate();
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const [promotions, setPromotions] = useState([]);
  const [promotionModal, setPromotionModal] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState(null);

  const fetchPromotions = async () => {
    const response = await actions.getBlogs(`/promotion/rest/promotions/?is_visible=true`);
    if (response) {
      setPromotions(response.results);
    }
  };

  useEffect(() => {
    fetchPromotions()
  }, [])

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container className={`mt-5 pt-5 d-flex flex-column ${large ? "justify-content-center align-items-center" : ""}`}>
          <div
            className="px-3 text-primary body-text2 d-flex justify-content-center align-items-center"
            style={{ height: 28, borderRadius: 14, backgroundColor: "#e6e7fa" }}
          >
            <b>{t("promotions")}</b>
          </div>
          <div className="page-header my-3">
            {t("findOurOngoingPromotions")}
          </div>
          <div className="subtitle-400 text-secondary text-center mb-5">
            {t("findOurOngoingPromotionsText")}
          </div>
          <div
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container className="mt-4">
        <Row className={`${large ? "row-cols-2" : "rows-cols-1"}`}>
          <Col>
            {promotions?.filter((d, i) => i % 2 === 0)?.map((item) => {
              return (
                <Card
                  className="mb-4"
                  style={{ border: "1px solid #EAECF0", borderRadius: 16 }}
                >
                  <Image
                    className="mb-2"
                    src={item.image}
                    alt={item.title}
                    style={{ borderRadius: "16px 16px 0px 0px" }}
                  />
                  <div className="w-100 p-4">
                    <h4 className="">{item.title}</h4>
                    <div className="mb-4 body-text1 text-secondary">
                      {item.description}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          setPromotionModal(true)
                          setSelectedPromotion(item)
                        }}
                      >
                        <b className="me-2">{t("knowMore")}</b>
                        <MdOutlineArrowOutward size={20} />
                      </div>
                      <a
                        className="d-flex align-items-center text-decoration-none"
                        href={`https://wa.me/+971527396758`}
                        target="_blank"
                        rel="noopener"
                      >
                        <IoLogoWhatsapp size={24} color="#25d366" />
                      </a>
                    </div>
                  </div>
                </Card>
              );
            })}
          </Col>
          <Col>
            {promotions?.filter((d, i) => i % 2 === 1)?.map((item) => {
              return (
                <Card
                  className="mb-4"
                  style={{ border: "1px solid #EAECF0", borderRadius: 16 }}
                >
                  <Image
                    className="mb-2"
                    src={item.image}
                    alt={item.title}
                    style={{ borderRadius: "16px 16px 0px 0px" }}
                  />
                  <div className="w-100 p-4">
                    <h4 className="">{item.title}</h4>
                    <div className="mb-4 body-text1 text-secondary">
                      {item.description}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="text-primary"
                        onClick={() => {
                          setPromotionModal(true)
                          setSelectedPromotion(item)
                        }}
                      >
                        <b className="me-2">{t("knowMore")}</b>
                        <MdOutlineArrowOutward size={20} />
                      </div>
                      <a
                        className="d-flex align-items-center text-decoration-none"
                        href={`https://wa.me/+971527396758`}
                        target="_blank"
                        rel="noopener"
                      >
                        <IoLogoWhatsapp size={24} color="#25d366" />
                      </a>
                    </div>
                  </div>
                </Card>
              );
            })}
          </Col>
        </Row>
      </Container>
      <Subscribe />
      <PromotionModal
        promotionModal={promotionModal}
        setPromotionModal={setPromotionModal}
        selectedPromotion={selectedPromotion}
      />
    </>
  );
};

export default Promotions;
