import { paperPlane } from "assets";
import React, { useRef, useState } from "react";
import { Button, Col, Container, Form, Image, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import * as utilitySlice from "reducer/utilitySlice";
import { notify } from "utilities/notifications/Notify";

const Subscribe = () => {
  const { t } = useTranslation();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const form = useRef(null);
  const [subscribing, setSubscribing] = useState(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setSubscribing(true);
    const formData = new FormData(form.current);
    formData.append('subscribed', true);
    const response = await utilitySlice.subscribe(formData);
    if (response.status === 201) {
      form.current.reset();
      notify(
        "Thank you for subscribing. You're now in the loop!",
        "success"
      );
    } else {
      notify("There was some problem subscribing!", "error");
    }
    setSubscribing(false);
  };

  return (
    <Container
      className="position-relative py-5"
      style={{ minHeight: large ? "30vh" : "20vh" }}
    >
      <div
        className="py-5"
        style={{
          minHeight: "20vh",
          backgroundColor: "#F2F5F7",
          borderRadius: 16,
        }}
      >
        <Row className={`${small ? "row-cols-2" : "row-cols-1"} py-0 px-4 m-0`}>
          <Col className="d-flex flex-column justify-content-center align-items-center">
            <div className="font-size32 poppins-500">
              {t("joinOurSubscribers")}
            </div>
            <div className="subtitle-400 text-secondary mt-2">
              {t("Stay in the loop with everything you need to know.")}
            </div>
          </Col>
          <Col className={`d-flex justify-content-center align-items-center ${small ? "w-50" : "w-100 mt-3"}`}>
            <Form
              ref={form}
              className={`p-0 d-flex justify-content-center align-items-center ${xlarge ? "" : "flex-column"}`}
              onSubmit={(e) => handleSubscribe(e)}
            >
              <Form.Control
                name="email"
                type="text"
                className="px-3 bg-white"
                required
                placeholder={t("enterYourEmail")}
                style={{
                  height: large ? 60 : 50,
                  maxWidth: 350,
                  borderRadius: 30,
                  border: "1px solid #D0D5DD",
                }}
              />
              <Button
                className={`px-4 bg-primary text-white primary-button border border-primary ${xlarge ? "ms-4" : "mt-3"}`}
                type="submit"
                disabled={subscribing}
                style={{
                  borderRadius: 40,
                  height: large ? 60 : 50,
                }}
              >
                {subscribing ? (
                  <span>
                    <Spinner size="sm" className="me-2" />
                    {t("subscribing")}...
                  </span>
                ) : (
                  <span className="poppins">{t("subscribe")}</span>
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
      <Image
        src={paperPlane}
        alt="Paper Plane"
        className="img-fluid position-absolute end-0 bottom-0"
        style={{ width: 240 }}
      />
    </Container>
  );
};

export default Subscribe;
