import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "reducer/utilitySlice";

const GoogleTranslate = () => {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.utilities.language);

    useEffect(() => {
        const addGoogleTranslateScript = () => {
            if (!document.querySelector('#google-translate-script')) {
                const script = document.createElement('script');
                script.id = 'google-translate-script';
                script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
                document.body.appendChild(script);
            }
        };

        window.googleTranslateElementInit = () => {
            if (!window.googleTranslateElement) {
                window.googleTranslateElement = new window.google.translate.TranslateElement(
                    {
                        pageLanguage: 'en',
                        includedLanguages: 'en,ar,fr,it,es,de,ru,ko,ja,nl,zh-CN,zh-TW,hu',
                        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
                    },
                    'google_translate_element'
                );
            }
        };

        addGoogleTranslateScript();

        const interval = setInterval(() => {
            const selectedLang = document.querySelector('.goog-te-menu-value span')?.innerText;
            if (selectedLang) {
                dispatch(actions.setLanguage(selectedLang.includes('Arabic') ? "ar" : "en"));
            }
        }, 1000);

        return () => {
            clearInterval(interval);
            if (window.googleTranslateElement) {
                window.googleTranslateElement = null;
            }
        };
    }, []);

    return (
        <div>
            {/* Inline style to hide toolbar */}
            {/* <style>
                {`
                .goog-te-banner-frame.skiptranslate {
                    display: none !important;
                }
                body {
                    top: 0 !important;
                }
                `}
            </style> */}

            <div className="bg-tertiary" dir={language === "ar" ? 'rtl' : 'ltr'} style={{ textAlign: language === "ar" ? 'right' : 'left' }}>
                <div id="google_translate_element"></div>
            </div>
        </div>
    );
};

export default GoogleTranslate;