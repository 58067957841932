import React from "react";
import { proServiceHelp } from "assets";
import { Col, Container, Image, Row, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { FaAddressCard, FaEnvelopeOpenText, FaFileExport, FaFileImport, FaMoneyBillTransfer, FaMoneyBillTrendUp, FaPassport } from "react-icons/fa6";
import { MdBusinessCenter, MdImportExport } from "react-icons/md";
import { BiSolidCertification } from "react-icons/bi";
import { TbCertificate } from "react-icons/tb";
import { BsBank2 } from "react-icons/bs";

const Vat = () => {
    const { t } = useTranslation();
    const large = useMediaQuery({ query: "(min-width: 992px)" });

    const requiredDocuments = [
        {
            id: 1,
            icon: <FaEnvelopeOpenText size={40} color="#1565c0" />,
            description: "Application letter with signature of individual/authorized signatory",
        },
        {
            id: 2,
            icon: <FaPassport size={40} color="#1565c0" />,
            description: "ID proof of the authorized party such as a passport copy or Emirates Id",
        },
        {
            id: 3,
            icon: <FaAddressCard size={40} color="#1565c0" />,
            description: "Copy of the company’s trade license",
        },
        {
            id: 4,
            icon: <BiSolidCertification size={40} color="#1565c0" />,
            description: "Certificate of Incorporation of the company",
        },
        {
            id: 5,
            icon: <TbCertificate size={40} color="#1565c0" />,
            description: "Articles of Association Certificate of the company",
        },
        {
            id: 6,
            icon: <TbCertificate size={40} color="#1565c0" />,
            description: "Power of Attorney Certificate",
        },
        {
            id: 7,
            icon: <MdBusinessCenter size={40} color="#1565c0" />,
            description: "Details of the business",
        },
        {
            id: 8,
            icon: <FaMoneyBillTransfer size={40} color="#1565c0" />,
            description: "Last 12 months’ turnover",
        },
        {
            id: 9,
            icon: <FaMoneyBillTrendUp size={40} color="#1565c0" />,
            description: "Expected turnover in the next 30 days",
        },
        {
            id: 10,
            icon: <FaFileImport size={40} color="#1565c0" />,
            description: "One year estimated imports from all GCC countries",
        },
        {
            id: 11,
            icon: <FaFileExport size={40} color="#1565c0" />,
            description: "One year estimated exports for all GCC countries",
        },
        {
            id: 12,
            icon: <MdImportExport size={40} color="#1565c0" />,
            description: "Supporting documents for customs registration",
        },
        {
            id: 13,
            icon: <BsBank2 size={40} color="#1565c0" />,
            description: "Bank account details",
        },
    ];

    return (
        <>
            <Container>
                <div>
                    <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
                        <b>{t("vatRegistration")}</b>
                    </div>
                    <div className="page-header my-2">
                        Process of VAT Registration
                    </div>
                    <div className="subtitle-400 text-secondary mb-3">
                        The VAT registration process outlined below is straightforward. SKV Tax Consultants and
                        Accountants offers online VAT registration services to simplify this process for your business.
                    </div>
                    <div className="subtitle-400 text-secondary mb-3">
                        <ul>
                            <li>Go to the VAT registration portal at www.tax.gov.ae and create an account.</li>
                            <li>After creating your account, log in and accurately complete the application form.</li>
                            <li>Upload all the required scanned documents for registration.</li>
                            <li>Submit the application form.</li>
                        </ul>
                    </div>
                    <div className="subtitle-400 text-secondary mb-5">
                        After submitting the application, the FTA reviews it, and upon approval, a Tax Registration
                        Number (TRN) is issued. Once the VAT registration process is finalized, the Ministry of Finance
                        issues a VAT certificate. This certificate serves as official proof that your business is
                        registered under VAT law and includes important details such as your VAT registration number,
                        registration date, issue date, business address, first VAT return due date, and the start and
                        end dates of the tax period.
                    </div>
                </div>
            </Container>
            <Container fluid className="bg-tertiary">
                <Container>
                    <div className={`py-5`}>
                        <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
                            <b>{t("vatRegistration")}</b>
                        </div>
                        <div className={`page-header my-2 ${large ? "w-75" : ""}`}>
                            Types of VAT Registration, Services and Exemptions
                        </div>

                        <h4 className="mt-3">VAT Registrations:</h4>
                        <div className="subtitle-400 text-secondary mb-3">
                            In the UAE, VAT registration can be either mandatory or voluntary, depending on a business's annual turnover.
                        </div>
                        <div className="subtitle-400 text-secondary mb-3">
                            <ul>
                                <li>
                                    <b>Mandatory - </b>
                                    If a business's annual turnover exceeds the tax registration threshold, or is expected to, VAT
                                    registration becomes mandatory. Specifically, if the value of taxable supplies and imports surpasses
                                    AED 375,000, registration is required. However, this threshold does not apply to foreign businesses.
                                    International companies selling to UAE customers must register for VAT regardless of their turnover.
                                </li>
                                <li>
                                    <b>Voluntary - </b>
                                    For voluntary VAT registration, the threshold is AED 187,500. A company with taxable supplies and
                                    imports exceeding the voluntary threshold but below the mandatory threshold can choose to register
                                    for VAT voluntarily.
                                </li>
                            </ul>
                        </div>

                        <h4 className="mt-4">VAT Services provided by SKV Business:</h4>
                        <div className="subtitle-400 text-secondary">
                            <ul>
                                <li>VAT Registration</li>
                                <li>VAT Amendment</li>
                                <li>VAT Filing</li>
                                <li>VAT De Registration</li>
                                <li>VAT Accounting Assistance</li>
                            </ul>
                        </div>

                        <h4 className="mt-4">Sectors Exempt from VAT</h4>
                        <div className="subtitle-400 text-secondary">
                            There are certain businesses that are exempt from VAT registration. These are,
                            <ul>
                                <li>Certain financial services</li>
                                <li>Residential properties</li>
                                <li>Bare land</li>
                                <li>Local passenger transport</li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </Container>
            <Container>
                <div className={`pt-5 ${large ? "w-75" : ""}`}>
                    <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
                        <b>{t("vatRegistration")}</b>
                    </div>
                    <div className="page-header my-2">
                        Documents Required for VAT Registration
                    </div>
                    <div className="subtitle-400 text-secondary mb-5">
                        Below is the list of documents needed to apply for VAT registration.
                    </div>
                </div>
                <Row className={`pb-5 ${large ? "row-cols-3" : "row-cols-1"}`}>
                    {requiredDocuments.map((item) => {
                        return (
                            <Col className="mb-5">
                                <Card
                                    className="p-4 d-flex justify-content-between border-0"
                                    style={{ height: 250, background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}
                                >
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            width: 80,
                                            height: 80,
                                            border: "10px solid #ffffff",
                                            borderRadius: 40,
                                        }}
                                    >
                                        {item.icon}
                                    </div>
                                    <div>
                                        <div className="subtitle mb-2">{item.description}</div>
                                    </div>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </>
    )
}

export default Vat;