import React from "react";
import { Image, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { MdOutlineArrowOutward } from "react-icons/md";
import { truncateText } from "utilities/trimText";
import { useMediaQuery } from "react-responsive";
import { authorDefault } from "assets";

const BlogCard = ({ blog }) => {
  const navigate = useNavigate();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <Card
      className="mb-4 blog-card cursor-pointer border-0 d-flex justify-content-between"
      style={{ borderRadius: 16, minHeight: large ? 750 : "" }}
      onClick={() => navigate(`/blogs/${blog?.slug}`)}
    >
      <div>
        <Card
          className="border-0 m-4 overflow-hidden d-flex justify-content-center align-items-center"
          dark={true}
          outlined
          style={{
            height: large ? 320 : "",
            border: "1px solid #3D3D3D",
            borderRadius: 16,
          }}
        >
          <Image
            style={{ borderRadius: 16 }}
            className={`${large ? "h-100" : "w-100"}`}
            src={blog?.blog_image}
            alt={blog?.title}
          />
        </Card>
        <div className="px-4">
          <div className="text-primary mb-3">
            <b>{blog?.tags_array && blog?.tags_array[0]}</b>
          </div>
          <div className="d-flex justify-content-between">
            <h4 className="">{truncateText(blog?.title, 50)}</h4>
            <span>
              <MdOutlineArrowOutward size={25} />
            </span>
          </div>
          <div className="text-secondary">{truncateText(blog?.description, 200)}</div>
        </div>
      </div>
      <div className="d-flex align-items-center p-4">
        <div className="overflow-hidden" style={{ width: 50, height: 50, borderRadius: "50%" }}>
          <Image className="w-100" src={blog?.author_image || authorDefault} alt={blog?.author_name} />
        </div>
        <div className="ms-3 d-flex flex-column">
          <b>{blog?.author_name}</b>
          <span>{moment(blog?.published_date).format("DD MMM YYYY")}</span>
        </div>
      </div>
    </Card>
  );
};

export default BlogCard;
