import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Subscribe, BoostBusiness, FAQ, PlanCard, GetInTouchFormSelect } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { proServiceMain, proServiceHelp } from "assets";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";

import Vat from "./Vat";
import CorporateTax from "./CorporateTax";

const VatCorpTaxRegistration = () => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const [isVat, setIsVat] = useState(true);

  const faqs = [
    {
      id: 1,
      question: "What is VAT?",
      answer: "VAT, or Value Added Tax, is an indirect tax commonly referred to as a general consumption tax. In countries where VAT is applicable, it is levied on most goods and services that are bought and sold."
    },
    {
      id: 2,
      question: "Is a real estate owner required to register for VAT?",
      answer: "Owners of residential properties who only provide exempt supplies are not required to register for VAT, provided they have no taxable business activities. However, if they are engaged in taxable business activities, they should assess their VAT obligations. Owners of non-residential properties must register if the value of their supplies exceeds AED 375,000 in the past 12 months or is expected to do so within the next 30 days."
    },
    {
      id: 3,
      question: "Why is VAT being introduced in the UAE?",
      answer: "The UAE government provides various public services to its citizens and residents, such as healthcare, infrastructure, education, and public safety, funded through the government budget. VAT offers a new revenue stream that supports the continued delivery of high-quality public services and helps the country reduce its reliance on oil and other hydrocarbons."
    },
    {
      id: 4,
      question: "How is VAT collected by the government?",
      answer: "Businesses are responsible for maintaining detailed records of their income, expenses, and VAT amounts. Registered businesses charge VAT to customers at the prevailing rate and pay VAT on their own purchases from suppliers. The difference between these amounts is either reclaimed or paid to the government."
    },
    {
      id: 5,
      question: "What is Corporate Tax (CT)?",
      answer: "Corporate Tax (CT) is a direct tax imposed on the net income or profits of businesses and corporations. In some countries, it is also referred to as Corporate Income Tax or Business Profits Tax."
    },
    {
      id: 6,
      question: "Why is Corporate Tax being introduced in the UAE?",
      answer: "The introduction of a competitive Corporate Tax regime, aligned with international best practices, aims to strengthen the UAE’s position as a global business and investment hub, driving development and achieving strategic goals. It also demonstrates the UAE’s commitment to international tax standards and transparency."
    },
    {
      id: 7,
      question: "Will Corporate Tax replace Excise Tax in the UAE?",
      answer: "No, Corporate Tax and Excise Tax are distinct. Both taxes will remain in effect in the UAE."
    },
  ]

  const [plans, setPlans] = useState([]);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param2);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param2);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      dispatch(actions.setSessionId(response.id));
      navigate(`/services/${param2}/plan`);
    }
  };

  useEffect(() => {
    if (param2) {
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param2]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("ourServices")}</b>
          </div>
          <div className="page-header my-lg-3 my-2">{t("vatRegistrationAndCorporateTaxRegistration")}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            Taxation simplified with SKV Business Services
          </div>
          <div
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param2}/plan`)}
            >
              {t("process")}
            </Button>
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "ms-4" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <Card className="p-2 d-flex flex-row border-0" style={{ background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)", borderRadius: 40 }}>
          <Button
            className={`border-0 poppins-600 ${isVat ? "bg-primary text-white" : "bg-transparent text-primary"} ${large ? "" : "w-100"}`}
            style={{
              paddingLeft: isVat ? 40 : 20,
              paddingRight: isVat ? 40 : 20,
              borderRadius: 30,
              height: 60,
            }}
            onClick={() => setIsVat(true)}
          >
            VAT Registration
          </Button>
          <Button
            className={`border-0 poppins-600 ${isVat ? "bg-transparent text-primary" : "bg-primary text-white"} ${large ? "" : "w-100"}`}
            style={{
              paddingLeft: isVat ? 20 : 40,
              paddingRight: isVat ? 20 : 40,
              borderRadius: 30,
              height: 60,
            }}
            onClick={() => setIsVat(false)}
          >
            Corporate Tax Registration
          </Button>
        </Card>
      </div>
      <Container className="d-flex flex-column">
        <Row className={`mt-5 pt-5`}>
          <Col lg={8} className="pe-5">
            {isVat ?
              <>
                <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
                  <b>{t("vatRegistration")}</b>
                </div>
                <div className="page-header my-2">VAT Registration Service in Dubai</div>
                <div className="subtitle-400 text-secondary mb-3">{t("vatRegPara1")}</div>
                <div className="subtitle-400 text-secondary mb-3">{t("vatRegPara2")}</div>
              </>
              :
              <>
                <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
                  <b>{t("corporateTaxRegistration")}</b>
                </div>
                <div className="page-header my-2">Corporate Tax Registration Service in Dubai</div>
                <div className="subtitle-400 text-secondary mb-3">
                  Under Federal Decree Law 47, as enforced by the Federal Tax Authority (FTA), all entities
                  liable for corporate tax, including those operating in Free Zones, are required to obtain
                  a Corporate Tax Registration Number. This mandate also applies to Exempted Persons, who must
                  register as well.
                </div>
                <div className="subtitle-400 text-secondary mb-3">
                  Entities are required to file their Corporate Tax returns and pay any outstanding taxes
                  within nine months after the end of the relevant tax period.
                </div>
                <div className="subtitle-400 text-secondary mb-3">
                  Businesses subject to the UAE's corporate tax regulations must complete their registration
                  through the EmaraTax platform.
                </div>
              </>
            }
          </Col>
          <Col lg={4}>
            <Card className="mb-4" border="primary" style={{ borderRadius: 15, boxShadow: "8px 8px #2e3192" }}>
              <GetInTouchFormSelect />
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="my-5 bg-tertiary">
        <Container className="py-5 my-5">
          <div>
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("vatAndCorporateTaxRegistration")}</b>
            </div>
            <div className={`page-header my-2 ${large ? "w-75" : ""}`}>
              Why choose SKV for VAT and Corporate Tax Registration Services?
            </div>
          </div>
          <Row>
            <Col sm={6} lg={7}>
              <div className="subtitle-400 text-secondary mb-3">{t("vatRegSKVPara1")}</div>
              <div className="subtitle-400 text-secondary mb-3">{t("vatRegSKVPara2")}</div>
            </Col>
            <Col sm={6} lg={5}>
              <Image src={proServiceMain} className="w-100" style={{ borderRadius: 10 }} />
            </Col>
          </Row>
          <div className="subtitle-400 text-secondary mb-3">
            {t("vatRegSKVPara3")}
            <ul>
              <li>{t("vatRegSKVPoint1")}</li>
              <li>{t("vatRegSKVPoint2")}</li>
              <li>{t("vatRegSKVPoint3")}</li>
              <li>{t("vatRegSKVPoint4")}</li>
              <li>{t("vatRegSKVPoint5")}</li>
            </ul>
          </div>
        </Container>
      </Container>
      {isVat ?
        <Vat />
        :
        <CorporateTax />
      }
      <Container fluid className="py-5" style={{ background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}>
        <Container className="p-0 d-flex flex-column justify-content-center align-items-center py-5">
          <div>
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("documents")}</b>
            </div>
            <h3 className="my-2">{t("processDocuments")}</h3>
            <div className="subtitle-400 text-secondary mb-5">
              {t("letsStartWithDocumentation")}
            </div>
          </div>
          {plans.map((plan) => {
            return (
              <PlanCard
                plan={plan}
                reqDocumentTypes={reqDocumentTypes}
                serviceSlug={param2}
                createSession={createSession}
              />
            );
          })}
        </Container>
      </Container>
      <BoostBusiness />
      <FAQ inputFaqs={faqs} />
      <Subscribe />
    </>
  );
};

export default VatCorpTaxRegistration;
