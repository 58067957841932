import React, { useEffect, useState } from "react";
import { Button, Container, Image, Row, Col, Card, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { useTranslation } from "react-i18next";

import {
  BoostBusiness,
  BusinessSetupService,
  AllServices,
  SetUsApart,
  Subscribe,
  WhyChooseUs,
  OurNetworks,
} from "components";

import { BiSolidStar } from "react-icons/bi";

import { newSkvWhiteLogo, homeHeroBackgroundWebm, homeHeroBackgroundMp4 } from "assets";
import { Breakpoint } from "react-socks";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { FcGoogle } from "react-icons/fc";
import { SiTrustpilot } from "react-icons/si";


const RatingStars = ({ rating }) => {
  const { t, i18n } = useTranslation();

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<BiSolidStar color="#f6bb06" size={25} />);
    }
    return stars;
  };

  return <div>{renderStars()}</div>;
};

const Home = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const { t, i18n } = useTranslation();

  const [testimonials, setTestimonials] = useState([]);

  const fetchAllReviews = async () => {
    // const response = await actions.getGoogleReviews(`/marketing/rest/review/?show=True&review_type=GOOGLE`);
    const response = await actions.getGoogleReviews(`/marketing/rest/review/?show=True`);
    if (response) {
      setTestimonials(response.results);
    }
  }

  const fetchBlogs = async () => {
    const response = await actions.getBlogs(`/blog/rest/blogs/`);
    if (response) {
      setBlogs(response.results);
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchAllReviews();
  }, []);

  return (
    <>
      <Container fluid className="background-video-container">
        <Container
          className={`d-flex flex-column ${large
            ? "justify-content-center align-items-center"
            : "align-items-start mt-5 pt-5"
            }`}
          style={{ minHeight: "100vh" }}
        >
          <video autoPlay loop muted className="background-video">
            <source src={homeHeroBackgroundWebm} type="video/webm" />
            <source src={homeHeroBackgroundMp4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <div>
            <Button
              className="d-flex justify-content-center align-items-center text-primary px-1 py-0 mt-lg-0 mt-4"
              style={{ ...styles.milestoneButton, height: 32 }}
            >
              <Button
                className="d-flex justify-content-center align-items-center text-primary py-0"
                style={{ ...styles.milestoneButton, height: 24 }}
              >
                <b>{t("milestones")}</b>
              </Button>
              <span className="mx-2">
                <b>{t("42+ Countries served")}</b>&nbsp;
                <FaArrowRight />
              </span>
            </Button>
          </div> */}
          <div className="text-center w-100">
            <Image
              className={`animated-skv-logo ${large ? "mt-4" : "mt-5"}`}
              src={newSkvWhiteLogo}
              alt="SKV Logo"
              style={large ? { maxHeight: 350 } : { maxHeight: 250 }}
            />
          </div>
          <div
            className={`home-heading text-white mt-2 ${large ? "text-center" : ""
              }`}
            style={large ? { paddingLeft: 150, paddingRight: 150 } : {}}
          >
            <span className="poppins-600">Business Setup</span>
            <span className="poppins-200"> {t("inTheHeartof")} </span>
            <span className="poppins-600"> {t("dubai")}</span>
          </div>
          <div
            className={`subtitle-400 text-white ${large ? "text-center mb-3" : "mb-5"
              }`}
            style={large ? { paddingLeft: 235, paddingRight: 235 } : {}}
          >
            {t("Empowering Your Business Formation")}
          </div>
          <div className="text-center w-100">
            <Button
              style={styles.exploreButton}
              onClick={() => navigate(`/services`)}
            >
              <span className="poppins-600">{t("exploreOurServices")}</span>
            </Button>
          </div>
        </Container>
      </Container>

      <BusinessSetupService />

      <SetUsApart />

      <AllServices />

      <Container fluid className="bg-white">
        <OurNetworks backgroundColor="bg-white" />
      </Container>

      <Container fluid className="py-5" style={{ background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}>
        <Container className="d-flex flex-column justify-content-center align-items-center user-select-none">
          <div className="custom-header custom-blue-color mb-3">Hear From Our Clients</div>
          <div className="subtitle-400 text-secondary text-center mb-5">
            {t("whyChooseUsDesc")}
          </div>
          <Row className="d-flex justify-content-center align-items-center mb-4">
            <Col lg={4}>
              <div
                className="overflow-hidden d-flex justify-content-center align-items-center"
                style={{ borderRadius: 20, width: "100%", height: 300 }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d924361.1952189549!2d54.4940194!3d25.1767055!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b34b42b20c423d%3A0x85ba605fef2645d0!2sSKV%20Business%20Services!5e0!3m2!1sen!2sin!4v1729160692804!5m2!1sen!2sin"
                  width={large ? "400" : "100%"}
                  height="300"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                >
                </iframe>
              </div>
            </Col>
            <Col lg={8}>
              <Carousel controls={false} indicators={false}>
                {testimonials?.map((testimonial, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <Card
                        className="p-3 d-flex justify-content-start align-items-start border-0"
                        style={{ height: large ? 300 : "", background: "transparent" }}
                      >
                        <div className="d-flex align-items-center">
                          {/* {testimonial.profile_image ?
                            <Image
                              src={testimonial.profile_image}
                              alt={testimonial.full_name}
                              style={{ width: 56, height: 56, borderRadius: "50%" }}
                            />
                            : */}
                          <div className="bg-primary d-flex justify-content-center align-items-center h3 text-white" style={{ width: 56, height: 56, borderRadius: "50%" }}>
                            {testimonial.initial}
                          </div>
                          {/* } */}
                          <div className="ms-3 d-flex flex-column">
                            <b>{testimonial.full_name}</b>
                            <span>{moment(testimonial.date).format("DD-MMM-YYYY")}</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <RatingStars rating={testimonial.rating} />
                          {testimonial.review_type === "GOOGLE" ?
                            <FcGoogle className="ms-3" size={25} />
                            : testimonial.review_type === "TRUSTPILOT" ?
                              <div className="ms-3 p-1" style={{ backgroundColor: "#04da8d" }}>
                                <SiTrustpilot className="" color="#022a1c" size={25} />
                              </div>
                              :
                              <></>
                          }
                        </div>
                        <div className="font-size18 my-3">{testimonial.review}</div>
                      </Card>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* <WhyChooseUs /> */}

      <Breakpoint large up>
        <Container fluid className="d-flex flex-column align-items-center px-lg-5 py-5">
          <div className="custom-header custom-blue-color text-primary mb-3">{t("latestBlogs")}</div>
          <div className="subtitle-400 text-secondary text-center mb-5">
            {t("Interviews, tips, guides, industry best practices, and news.")}
          </div>
          <Container>
            <Row className="d-flex row-cols-2">
              {blogs.slice(0, 4).map((blog) => {
                return (
                  <Col>
                    <Card
                      className="overflow-hidden d-flex justify-content-center align-items-center p-0 m-0"
                      style={{ borderRadius: "20px", background: "#eff0fa", height: 400 }}
                    >
                      <a className="w-100 p-0" href={`/blogs/${blog.slug}`}>
                        <Image
                          className="w-100"
                          src={blog.blog_image}
                          alt={blog.title}
                          style={{ minHeight: 400, width: "auto" }}
                        />
                      </a>
                    </Card>
                    <h4 className="my-3">{blog.title}</h4>
                    <div className="mb-5">{blog.description}</div>
                  </Col>
                );
              })}
            </Row>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                className="bg-tertiary text-primary primary-button border border-primary"
                onClick={() => {
                  navigate(`/blogs`);
                }}
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  borderRadius: 30,
                  height: 60,
                }}
              >
                {t("viewAllBlogs")}
              </Button>
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <BoostBusiness />
      <Subscribe />
    </>
  );
};

const styles = {
  milestoneButton: {
    backgroundColor: "white",
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 16,
    border: "2px solid",
  },
  exploreButton: {
    paddingLeft: 45,
    paddingRight: 45,
    borderRadius: 55,
    height: 70,
  },
};

export default Home;
