import { GetInTouchFormSelect } from 'components';
import React, { useEffect, useState } from 'react';
import { Card, Col, Image, Modal, Row, Spinner } from 'react-bootstrap';
import { FaFile, FaFileImage, FaFilePdf, FaFileVideo } from 'react-icons/fa6';
import { IoMdDownload } from 'react-icons/io';
import { notify } from 'utilities/notifications/Notify';

const PromotionModal = ({ promotionModal, setPromotionModal, selectedPromotion }) => {

    const [downloading, setDownloading] = useState(false);
    const [videoFile, setVideoFile] = useState(null);

    const downloadMedia = async (url, file_name) => {
        try {
            setDownloading(true);
            if (url) {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("File not found, try again later");
                }
                const blob = await response.blob();
                const link = document.createElement('a');
                const objectUrl = URL.createObjectURL(blob);
                link.href = objectUrl;
                link.setAttribute('download', file_name);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(objectUrl);
                link.parentNode.removeChild(link);
            } else {
                notify("Files not found, try again later");
            }
        } catch (error) {
            notify(error.message || "Something went wrong, please try again.");
        } finally {
            setDownloading(false);
        }
    };

    useEffect(() => {
        if (selectedPromotion) {
            const check = selectedPromotion?.promotion_files?.filter(file => (file.extension === ".mp4" || file.extension === ".webm"))[0]
            setVideoFile(check ? check : null)
        }
    }, [selectedPromotion])

    return (
        <Modal
            dialogClassName="my-5 d-flex align-items-center h-100"
            contentClassName="bg-white border-R40"
            show={promotionModal}
            onHide={() => {
                setPromotionModal(false)
                setVideoFile(null)
            }}
            animation={false}
            size="xl"
        >
            <Modal.Body className="p-5">
                <Row className="w-100 d-flex d-flex justify-content-center">
                    <Col sm={6} className="m-0 p-0 d-flex justify-content-center">
                        <Card className="mb-4" border="primary" style={{ borderRadius: 15, boxShadow: "8px 8px #2e3192" }}>
                            <GetInTouchFormSelect />
                        </Card>
                    </Col>
                    <Col sm={6}>
                        {videoFile &&
                            <video autoPlay loop controls style={{ width: "100%" }}>
                                <source src={videoFile?.file} type="video/webm" />
                                <source src={videoFile?.file} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        }
                        <div className="body-text1 my-2">Download Files:</div>
                        {selectedPromotion?.promotion_files?.filter(f => (f.extension !== ".mp4" && f.extension !== ".webm"))
                            .map((file, index) => (
                                <Card
                                    key={index}
                                    className="d-flex flex-row justify-content-between align-items-center p-2 border-0"
                                >
                                    <div className="d-flex align-items-center">
                                        {file?.extension === ".pdf" ?
                                            <FaFilePdf className="me-2" size={30} color="red" />
                                            :
                                            (file?.extension === ".png" || file?.extension === ".jpg" || file?.extension === ".jpeg" || file?.extension === ".webp" || file?.extension === ".heic") ?
                                                <FaFileImage className="me-2" size={30} color="#83c8e4" />
                                                :
                                                (file?.extension === ".mp4" || file?.extension === ".webm") ?
                                                    <FaFileVideo className="me-2" size={30} color="green" />
                                                    :
                                                    <FaFile className="text-primary-500 me-2" size={30} />
                                        }
                                        <div className="d-flex flex-column body-text1">
                                            <span className="">{file?.file_name}</span>
                                            <span className="">{file?.size}</span>
                                        </div>
                                    </div>
                                    {downloading ?
                                        <span><Spinner size="sm" /></span>
                                        :
                                        <div className="cursor-pointer" onClick={() => downloadMedia(file?.file, file?.file_name)}>
                                            <IoMdDownload size={24} />
                                        </div>
                                    }
                                </Card>
                            ))}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default PromotionModal;