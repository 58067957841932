import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    language: "en",
    session: null,
}

///////////////////////// SUBSCRIBE /////////////////////////

export const subscribe = async (data) => {
    try {
        const response = await axios.post(`/marketing/rest/subscribe/`, data);
        if (response) {
            // console.log("subscribe Response", response);
            return response;
        }
    } catch (err) {
        // console.log("subscribe Error", err);
        return err;
    }
};

///////////////////////// CONTACT US /////////////////////////

export const getContactServices = async (data) => {
    try {
        console.log(data);
        const response = await axios.get(`/marketing/rest/contactservice/`);
        if (response) {
            return response;
        }
    } catch (err) {
        return err;
    }
};

export const addContact = async (data) => {
    try {
        console.log(data);
        const response = await axios.post(`/marketing/rest/contactus/`, data);
        if (response) {
            console.log("addContact Response", response);
            return response;
        }
    } catch (err) {
        console.log("addContact Error", err);
        return err;
    }
};

///////////////////////// LANGUAGES /////////////////////////

export const getLanguages = async () => {
    try {
        const response = await axios.get(`/language/rest/languages/`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

///////////////////////// BLOGS /////////////////////////

export const getBlog = async (id) => {
    try {
        const response = await axios.get(`/blog/rest/blogs/${id}/blog_by_slug/`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

export const getBlogs = async (url) => {
    try {
        const response = await axios.get(url);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

export const searchBlog = async (search_text) => {
    try {
        // console.log("Search text: ", search_text);
        const response = await axios.get(`/blog/rest/blogs/?search=${search_text}`);
        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }
    } catch (err) {
        console.log("searchBlog error", err);
        return err;
    }
};


///////////////////////// PLAN /////////////////////////

export const getService = async (serviceSlug) => {
    try {
        const response = await axios.get(`/plan/rest/services/${serviceSlug}/`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

export const getServicePlans = async (serviceSlug) => {
    try {
        const response = await axios.get(`/plan/rest/plans/?service__slug=${serviceSlug}`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

export const getChildServices = async (serviceSlug) => {
    try {
        const response = await axios.get(`/plan/rest/services/?parent_service__slug=${serviceSlug}`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

///////////////////////// SESSION /////////////////////////

export const getSession = async (id) => {
    try {
        const response = await axios.get(`/session/rest/session/${id}/`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

export const getSessions = async (userId) => {
    try {
        const response = await axios.get(`/session/rest/session/?user__id=${userId}`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

export const addSession = async (data) => {
    try {
        const response = await axios.post(`/session/rest/session/`, data);
        if (response.status === 201) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

export const updateSession = async (id, data) => {
    try {
        const response = await axios.patch(`/session/rest/session/${id}/`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

export const bulkCreateSessionDocument = async (data) => {
    try {
        console.log(data);
        const response = await axios.post(`/session/rest/sessiondocument/upload_session_documents/`, data);
        if (response.status === 201) {
            return response;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

export const deleteSessionDocument = async (data) => {
    try {
        const response = await axios.post(`/session/rest/sessiondocument/delete_document/`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

///////////////////////// SERVICE DOCUMENT TYPE /////////////////////////

export const getServiceDocumentTypes = async (serviceSlug) => {
    try {
        const response = await axios.get(`/plan/rest/servicedocumenttypes/?service__slug=${serviceSlug}`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

///////////////////////// ORDER /////////////////////////

export const getOrder = async (orderId) => {
    try {
        const response = await axios.get(`/order/rest/orders/${orderId}/`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return false;
    }
};

export const getOrders = async (userId) => {
    try {
        const response = await axios.get(`/order/rest/orders/?user__id=${userId}`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

export const createOrder = async (data) => {
    try {
        const response = await axios.post(`/order/rest/orders/`, data);
        if (response.status === 201) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

///////////////////////// REVIEWS /////////////////////////

export const getGoogleReviews = async (url) => {
    try {
        const response = await axios.get(url);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        return err?.response?.data;
    }
};

///////////////////////// SLICE /////////////////////////

const utilitySlices = createSlice({
    name: "utilities",
    initialState: initialState,
    reducers: {
        setLanguage(state, action) {
            const { payload } = action;
            state.language = payload;
        },
        setSessionId(state, action) {
            const { payload } = action;
            state.session = payload;
        },
        removeSessionId(state, action) {
            state.session = null;
        }
    }
});

export const { setLanguage, setSessionId, removeSessionId } = utilitySlices.actions;

export default utilitySlices.reducer;