import React, { useState, useEffect } from "react";
import { Col, Container, Row, Image, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { FiCopy } from "react-icons/fi";
import { FaLinkedinIn, FaSquareFacebook, FaXTwitter, FaWhatsapp } from "react-icons/fa6";
import { BlogCard, Subscribe } from "components";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { t } from "i18next";
import { authorDefault } from "assets";

const Blog = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [blog, setBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [blogContent, setBlogContent] = useState("");
  const share_url = window.location.href;
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(share_url).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {copied ? 'Copied!' : 'Copy link'}
    </Tooltip>
  );

  const fetchBlog = async () => {
    const response = await actions.getBlog(blogId);
    setBlog(response);
  };

  const fetchBlogs = async () => {
    const response = await actions.getBlogs(
      `/blog/rest/blogs/?ordering=-published_date`
    );
    if (response) {
      setBlogs(response?.results);
    }
  };

  useEffect(() => {
    fetchBlog();
    fetchBlogs();
  }, []);

  useEffect(() => {
    let x = blog?.content;
    let divElement = document.createElement("div");
    divElement.innerHTML = x;
    let elements = divElement.getElementsByTagName("*");
    for (let i = 0; i < elements.length; i++) {
      if ((elements[i].getAttribute("style") || "").includes("font-family")) {
        elements[i].removeAttribute("style");
      }
    }
    setBlogContent(divElement.innerHTML);
  }, [blog]);

  return (
    <Container fluid className="mt-5 pt-5">
      <Container className="my-lg-5 pt-5 user-select-none d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="text-primary poppins-600">
            {t("published")}{" "}
            {moment(blog?.published_date).format("DD MMM YYYY")}
          </div>
          <div className="mb-4 page-header d-flex justify-content-center align-items-center">
            {blog?.title}
          </div>
          <div className={`${large ? "d-flex mb-5" : "row row-cols-1 mx-0 mb-4"}`}>
            {blog?.tags_array?.map((arr, index) => {
              return (
                <div
                  key={index}
                  className={`${large ? "mx-3 px-3" : "col me-2 mb-2"} text-primary body-text2 d-flex justify-content-center align-items-center`}
                  style={{
                    width: "auto",
                    height: 28,
                    borderRadius: 14,
                    backgroundColor: "#e6e7fa",
                  }}
                >
                  {arr}
                </div>
              )
            })}
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center overflow-hidden border-0"
          style={{
            maxWidth: 840,
            maxHeight: "auto",
            borderRadius: 30,
          }}
        >
          <Image
            className="w-100"
            src={blog?.blog_image ? blog?.blog_image : ""}
            alt={blog?.title}
          />
        </div>
      </Container>
      <Container className="py-1 user-select-none" style={{ maxWidth: 840 }}>
        <div
          className="my-5"
          dangerouslySetInnerHTML={{ __html: blogContent }}
        />
        <hr style={{ color: "#98A2B3" }} />
      </Container>
      <Container
        className="user-select-none"
        style={{ maxWidth: 840 }}
      >
        <div className={`d-flex justify-content-between ${large ? "" : "flex-column align-items-center"}`}>
          <div className="d-flex justify-content-start align-items-center mb-lg-0 mb-3">
            <div className="overflow-hidden" style={{ width: 50, height: 50, borderRadius: "50%" }}>
              <Image className="w-100" src={blog?.author_image || authorDefault} alt={blog?.author_name} style={{ objectFit: "cover" }} />
            </div>
            <div className="ms-3 d-flex flex-column justify-content-center align-items-center">
              <p className="subtitle m-0">{blog?.author_name}</p>
              <div
                className="d-flex flex-column justify-content-between"
                style={{ fontSize: 12 }}
              >
                {blog?.published_date}&nbsp;&nbsp;&nbsp;&nbsp;
                {blog?.read_time}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start align-items-center">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <Button
                className="bg-white text-black poppins-500 me-1"
                onClick={copyToClipboard}
                style={{
                  paddingLeft: large ? 30 : 16,
                  paddingRight: large ? 30 : 16,
                  border: "1px solid #D0D5DD",
                  borderRadius: 30,
                  height: 48,
                }}
              >
                <FiCopy />
                {large && <span className="ms-2">{t("copyLink")}</span>}
              </Button>
            </OverlayTrigger>
            <WhatsappShareButton url={share_url}>
              <span
                className="d-flex justify-content-center align-items-center border mx-1"
                style={{ width: 48, height: 48, borderRadius: 24 }}
              >
                <FaWhatsapp size={20} color="#98A2B3" />
              </span>
            </WhatsappShareButton>
            <LinkedinShareButton url={share_url}>
              <span
                className="d-flex justify-content-center align-items-center border mx-1"
                style={{ width: 48, height: 48, borderRadius: 24 }}
              >
                <FaLinkedinIn size={20} color="#98A2B3" />
              </span>
            </LinkedinShareButton>
            <TwitterShareButton url={share_url}>
              <span
                className="d-flex justify-content-center align-items-center border mx-1"
                style={{ width: 48, height: 48, borderRadius: 24 }}
              >
                <FaXTwitter size={20} color="#98A2B3" />
              </span>
            </TwitterShareButton>
            <FacebookShareButton url={share_url}>
              <span
                className="d-flex justify-content-center align-items-center border mx-1"
                style={{ width: 48, height: 48, borderRadius: 24 }}
              >
                <FaSquareFacebook size={20} color="#98A2B3" />
              </span>
            </FacebookShareButton>
          </div>
        </div>
      </Container>
      <Container>
        <hr className="my-5" style={{ color: "#98A2B3" }} />
        <div className={`d-flex justify-content-between align-items-center ${large ? "" : "flex-column"}`}>
          <div>
            <h3>{t("moreBlogs")}</h3>
            <div className="subtitle-400 text-secondary">
              {t("theLatestIndustryNewsTechEtcText")}{" "}
            </div>
          </div>
          <Button
            className={`bg-tertiary text-primary primary-button poppins-600 ${large ? "" : "mt-4"}`}
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              borderRadius: 30,
              height: 60,
            }}
            onClick={() => navigate(`/blogs`)}
          >
            <span className="ms-2">Explore All</span>
          </Button>
        </div>
        <Row className={`my-5 ${large ? "row-cols-3" : "row-cols-1"}`}>
          {blogs.filter(bg => bg.id !== blog?.id).map((blog, index) => {
            if (index < 3)
              return (
                <Col>
                  <BlogCard blog={blog} />
                </Col>
              );
          })}
        </Row>
      </Container>
      <Subscribe />
    </Container>
  );
};

export default Blog;
