import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import {
  Subscribe,
  BoostBusiness,
  FAQ,
  ChatWithProfessionals,
  PlanCard,
  GetInTouchFormSelect,
} from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import {
  chat,
  zap,
  reservetradename,
  legalstructure,
  llcdubai,
  professionallicense,
  branchoffice,
  soleproprietorship,
  civilcompany,
  uaevisa,
  corporatebankaccount,
  teamwithreports,
} from "assets";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import { AiOutlineStock } from "react-icons/ai";
import { MdFamilyRestroom, MdOutlineWork } from "react-icons/md";
import { IoBusiness } from "react-icons/io5";
import { GrUserWorker } from "react-icons/gr";
import { FaAddressCard } from "react-icons/fa";
import { RiGovernmentFill } from "react-icons/ri";
import { TbLicense } from "react-icons/tb";

const MainlandBusinessSetup = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const topReasons = [
    {
      id: 1,
      icon: <AiOutlineStock size={25} />,
      title: t("investorVisa"),
      description: t("investorVisaText"),
    },
    {
      id: 2,
      icon: <MdOutlineWork size={25} />,
      title: t("workVisa"),
      description: t("workVisaText"),
    },
    {
      id: 3,
      icon: <MdFamilyRestroom size={25} />,
      title: t("familyResidenceVisa"),
      description: t("familyResidenceVisaText"),
    },
    {
      id: 4,
      icon: <IoBusiness size={25} />,
      title: t("businessLicense"),
      description: t("businessLicenseText"),
    },
    {
      id: 5,
      icon: <GrUserWorker size={25} />,
      title: t("labourCards"),
      description: t("labourCardsText"),
    },
    {
      id: 6,
      icon: <FaAddressCard size={25} />,
      title: t("emiratesId"),
      description: t("emiratesIdText"),
    },
    {
      id: 7,
      icon: <RiGovernmentFill size={25} />,
      title: t("governmentApprovals"),
      description: t("governmentApprovalsText"),
    },
    {
      id: 8,
      icon: <TbLicense size={25} />,
      title: t("renewalOfBusinessLicense"),
      description: t("renewalOfBusinessLicenseText"),
    },
  ];

  const businessActivities = [
    { id: 1, name: t("LLCDubai"), image: llcdubai },
    { id: 2, name: t("professionalLicense"), image: professionallicense },
    { id: 3, name: t("branchOffice"), image: branchoffice }
  ]

  const legalStructures = [
    { id: 1, name: t("soleProprietorship"), image: soleproprietorship },
    { id: 2, name: t("civilCompany"), image: civilcompany },
    { id: 3, name: t("limitedLiabilityCompany"), image: llcdubai },
    { id: 4, name: t("branchesAndRepresentationOffices"), image: branchoffice },
  ]

  const pathways = [
    {
      id: 1,
      title: t("chooseYourBusinessActivity"),
      description: t("chooseYourBusinessActivityText"),
      image: (
        <Row className="row-cols-2">
          {businessActivities.map((businessActivity) => {
            return (
              <Col className="mb-4">
                <Card
                  className="p-4 d-flex justify-content-center align-items-center border-0"
                  style={{
                    height: 280,
                    backgroundColor: "#eff0fa",
                    borderRadius: 20,
                  }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#ffffff",
                      width: 150,
                      height: 150,
                      borderRadius: 75,
                    }}
                  >
                    <Image src={businessActivity.image} alt={businessActivity.name} className="animated-image" style={{ height: 100 }} />
                  </div>
                  <div className="subtitle text-center mt-4">{businessActivity.name}</div>
                </Card>
              </Col>
            )
          })}
        </Row>
      ),
    },
    {
      id: 2,
      title: t("selectALegalStructure"),
      description: t("selectALegalStructureText"),
      image: (
        <Row className="row-cols-2">
          {legalStructures.map((legalStructure) => {
            return (
              <Col className="mb-4">
                <Card
                  className="p-4 d-flex justify-content-center align-items-center border-0"
                  style={{
                    height: 280,
                    backgroundColor: "#eff0fa",
                    borderRadius: 20,
                  }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#ffffff",
                      width: 150,
                      height: 150,
                      borderRadius: 75,
                    }}
                  >
                    <Image src={legalStructure.image} alt={legalStructure.name} className="bouncy-image" style={{ height: 100 }} />
                  </div>
                  <div className="subtitle text-center mt-4">{legalStructure.name}</div>
                </Card>
              </Col>
            )
          })}
        </Row>
      ),
    },
    {
      id: 3,
      title: t("reserveATradeName"),
      description: t("reserveATradeNameText"),
      image: (
        <Image
          className="w-100"
          src={reservetradename}
          alt="Reserve Company Name"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 4,
      title: t("rentOfficeSpace"),
      description: t("rentOfficeSpace"),
      image: (
        <Image
          className="w-100"
          src={legalstructure}
          alt="Legal Structure"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 5,
      title: t("obtainVisas"),
      description: t("obtainVisasText"),
      image: (
        <Image className="w-100" src={uaevisa} alt="UAE Visa" style={{ borderRadius: 20 }} />
      ),
    },
    {
      id: 6,
      title: t("openACorporateBankAccount"),
      description: t("openACorporateBankAccountText"),
      image: (
        <Image
          className="w-100"
          src={corporatebankaccount}
          alt="Corporate Bank Account"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 7,
      title: t("manageYourTeamWithReports"),
      description: t("manageYourTeamWithReportsText"),
      image: (
        <Image
          className="w-100"
          src={teamwithreports}
          alt="Managing Reports"
          style={{ borderRadius: 20 }}
        />
      ),
    },
  ];

  const [index, set] = useState(0);
  const [currentPathway, setCurrentPathway] = useState(0);
  const [service, setService] = useState(null);
  const [plans, setPlans] = useState([]);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchService = async () => {
    const response = await actions.getService(param2);
    if (response) {
      setService(response);
    }
  };

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param2);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param2);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      dispatch(actions.setSessionId(response.id));
      navigate(`/services/${param2}/plan`);
    }
  };

  useEffect(() => {
    if (param2) {
      fetchService();
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param2]);

  useEffect(() => {
    let timer = setTimeout(() => {
      set((state) => (state + 1) % 7);
      setCurrentPathway((state) => (state + 1) % 7);
    }, 5000);
    return () => clearTimeout(timer);
  }, [index]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("ourServices")}</b>
          </div>
          <div className="page-header my-3">{service?.title}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            UAE Mainland setup offers unrestricted local and international trade access.
          </div>
          <div
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param2}/plan`)}
            >
              {t("process")}
            </Button>
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "ms-4" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container className="d-flex flex-column">
        <Row className={`mt-5 pt-5`}>
          <Col lg={7}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: 80,
                height: 80,
                border: "12px solid #F9F5FF",
                borderRadius: 40,
                backgroundColor: "#F4EBFF",
              }}
            >
              <Image src={zap} alt="zap" style={{ width: 40, height: 40 }} />
            </div>
            <h4 className="my-3">
              {t("Unlocking Business Potential with a UAE Mainland License")}
            </h4>
            <div className="subtitle-400 text-secondary mb-3">
              {t("mainlandLicenseText")}
            </div>
          </Col>
          <Col lg={1}></Col>
          <Col lg={4}>
            <Card className="mb-4" border="primary" style={{ borderRadius: 15, boxShadow: "8px 8px #2e3192" }}>
              <GetInTouchFormSelect />
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bg-tertiary">
        <Container className="mb-5 pb-5">
          <div className="mt-5 pt-5 w-75">
            <div className="mt-5 text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("services")}</b>
            </div>
            <h3 className="my-2">{t("comprehensiveServicesForMBS")}</h3>
            <div className="subtitle-400 text-secondary mb-5">
              {t("comprehensiveServicesTextForMBS")}
            </div>
          </div>
          <Row className={`${large ? "row-cols-4" : "row-cols-1"}`}>
            {topReasons.map((reason) => {
              return (
                <Col className="mb-5">
                  <Card
                    className="p-4 d-flex justify-content-between border-0"
                    style={{ height: 280 }}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        width: 60,
                        height: 60,
                        border: "8px solid #F9F5FF",
                        borderRadius: 30,
                        backgroundColor: "#F4EBFF",
                      }}
                    >
                      {reason.icon}
                    </div>
                    <div>
                      <div className="subtitle mb-2">{reason.title}</div>
                      <div className="font-size16 text-secondary">
                        {reason.description}
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param2}/plan`)}
            >
              {t("process")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container className="my-5 py-5">
        <div className={`${large ? "w-75" : "w-100"}`}>
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("stepsAndRequirements")}</b>
          </div>
          <h3 className="my-2">{t("yourPathwayToBusinessSuccess")}</h3>
          <div className="subtitle-400 text-secondary mb-5">
            {t("exploreStepsAndRequirementsText")}
          </div>
        </div>
        <Row>
          <Col sm={6} className="">
            {pathways.map((pathway, index) => {
              return (
                <>
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderLeft:
                        currentPathway === index
                          ? "4px solid #2E3192"
                          : "4px solid #F2F4F7",
                    }}
                    onClick={() => setCurrentPathway(index)}
                  >
                    <div className="subtitle mb-2">{pathway.title}</div>
                    <div className="font-size16 text-secondary">
                      {pathway.description}
                    </div>
                  </div>
                  {currentPathway === index && !large && pathways[currentPathway].image}
                </>
              );
            })}
          </Col>
          {large && <Col sm={6}>
            <div className="sticky">
              {pathways[currentPathway].image}
            </div>
          </Col>}
        </Row>
      </Container>
      <Container fluid className="bg-tertiary py-5 mb-5">
        <Container className="py-5 d-flex flex-column justify-content-center align-items-center">
          <div className="">
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("documents")}</b>
            </div>
            <h3 className="my-2">Plans that fit your scale</h3>
            <div className="subtitle-400 text-secondary mb-5">
              {t("letsStartWithDocumentation")}
            </div>
          </div>
          {plans.map((plan) => {
            return (
              <PlanCard
                plan={plan}
                reqDocumentTypes={reqDocumentTypes}
                serviceSlug={param2}
                createSession={createSession}
              />
            );
          })}
        </Container>
      </Container>
      <ChatWithProfessionals />
      <FAQ />
      <BoostBusiness />
      <Subscribe />
    </>
  );
};

export default MainlandBusinessSetup;
