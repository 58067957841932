import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import {
  Subscribe,
  BoostBusiness,
  FAQ,
  ChatWithProfessionals,
  PlanCard,
  GetInTouchFormSelect,
} from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import {
  chat,
  rightfreezone,
  llcdubai,
  professionallicense,
  branchoffice,
  reservetradename,
  submitapplication,
  legaldocumentation,
  corporatebankaccount,
  uaevisa,
  complaincerenewal,
  zap,
} from "assets";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";

const FreeZoneBusinessSetup = () => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const pathways = [
    {
      id: 1,
      title: t("choosingTheRightFreeZone"),
      description: t("choosingTheRightFreeZoneText"),
      image: (
        <Image
          className="w-100"
          src={rightfreezone}
          alt="Freezone Photo"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 2,
      title: t("selectingBusinessActivity"),
      description: t("selectingBusinessActivityText"),
      image: (
        <Row className="row-cols-2">
          <Col className="mb-4">
            <Card
              className="p-4 d-flex justify-content-center align-items-center border-0"
              style={{
                height: 280,
                backgroundColor: "#ffffff",
                borderRadius: 20,
              }}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#eff0fa",
                  width: 150,
                  height: 150,
                  borderRadius: 75,
                }}
              >
                <Image src={llcdubai} alt="LLC Dubai" className="animated-image" style={{ height: 100 }} />
              </div>
              <div className="subtitle text-center mt-4">LLC Dubai</div>
            </Card>
          </Col>
          <Col className="mb-4">
            <Card
              className="p-4 d-flex justify-content-center align-items-center border-0"
              style={{
                height: 280,
                backgroundColor: "#ffffff",
                borderRadius: 20,
              }}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#eff0fa",
                  width: 150,
                  height: 150,
                  borderRadius: 75,
                }}
              >
                <Image src={professionallicense} alt="Professional License" className="animated-image" style={{ height: 100 }} />
              </div>
              <div className="subtitle text-center mt-4">
                Professional License
              </div>
            </Card>
          </Col>
          <Col className="mb-4">
            <Card
              className="p-4 d-flex justify-content-center align-items-center border-0"
              style={{
                height: 280,
                backgroundColor: "#ffffff",
                borderRadius: 20,
              }}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#eff0fa",
                  width: 150,
                  height: 150,
                  borderRadius: 75,
                }}
              >
                <Image src={branchoffice} alt="Branch Office" className="animated-image" style={{ height: 100 }} />
              </div>
              <div className="subtitle text-center mt-4">Branch Office</div>
            </Card>
          </Col>
        </Row>
      ),
    },
    {
      id: 3,
      title: t("selectingCompanyName"),
      description: t("selectingCompanyNameText"),
      image: (
        <Image
          className="w-100"
          src={reservetradename}
          alt="Reserve Company Name"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 4,
      title: t("submittingApplicationAndDocs"),
      description: (
        <>
          {t("submittingApplicationAndDocsText")} <br />
          <ul>
            <li>{t("submittingApplicationAndDocsPoint1")}</li>
            <li>{t("submittingApplicationAndDocsPoint2")}</li>
            <li>{t("submittingApplicationAndDocsPoint3")}</li>
            <li>{t("submittingApplicationAndDocsPoint4")}</li>
            <li>{t("submittingApplicationAndDocsPoint5")} </li>
          </ul>
        </>
      ),
      image: (
        <Image
          className="w-100"
          src={submitapplication}
          alt="Submit Application"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 5,
      title: t("legalDocumentationAndLicenseApplication"),
      description: (
        <ul>
          <li>{t("legalDocumentationAndLicenseApplicationText1")}</li>
          <li>{t("legalDocumentationAndLicenseApplicationText2")}</li>
          <li>{t("legalDocumentationAndLicenseApplicationText3")}</li>
        </ul>
      ),
      image: (
        <Image
          className="w-100"
          src={legaldocumentation}
          alt="Legal Documentation"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 6,
      title: t("bankAccountOpening"),
      description: t("bankAccountOpeningText"),
      image: (
        <Image
          className="w-100"
          src={corporatebankaccount}
          alt="Corporate Bank Account"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 7,
      title: t("visaProcessing"),
      description: (
        <ul>
          <li>{t("visaProcessingText1")}</li>
          <li>{t("visaProcessingText2")}</li>
        </ul>
      ),
      image: (
        <Image className="w-100" src={uaevisa} alt="UAE Visa" style={{ borderRadius: 20 }} />
      ),
    },
    {
      id: 8,
      title: "Compliance and Renewals",
      description: (
        <ul>
          <li>{t("complianceAndRenewals1")}</li>
          <li>{t("complianceAndRenewals2")}</li>
        </ul>
      ),
      image: (
        <Image
          className="w-100"
          src={complaincerenewal}
          alt="Complaince Renewal"
          style={{ borderRadius: 20 }}
        />
      ),
    },
  ];
  const topReasons = [
    {
      id: 1,
      icon: chat,
      title: t("noNeedForPhysicalPresence"),
      description: t("noNeedForPhysicalPresenceText"),
    },
    {
      id: 2,
      icon: chat,
      title: t("limitedDirectinteractionWithGov"),
      description: t("limitedDirectinteractionWithGovText"),
    },
    {
      id: 3,
      icon: chat,
      title: t("exemptionFromVAT"),
      description: t("exemptionFromVATText"),
    },
    {
      id: 4,
      icon: chat,
      title: t("corporateTaxExemption"),
      description: t("corporateTaxExemptionText"),
    },
    {
      id: 5,
      icon: chat,
      title: t("versatileBusinessType"),
      description: t("versatileBusinessTypeText"),
    },
  ];

  const [index, set] = useState(0);
  const [currentPathway, setCurrentPathway] = useState(0);
  const [service, setService] = useState(null);
  const [plans, setPlans] = useState([]);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchService = async () => {
    const response = await actions.getService(param2);
    if (response) {
      setService(response);
    }
  };

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param2);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param2);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      dispatch(actions.setSessionId(response.id));
      navigate(`/services/${param2}/plan`);
    }
  };

  useEffect(() => {
    if (param2) {
      fetchService();
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param2]);

  useEffect(() => {
    let timer = setTimeout(() => {
      set((state) => (state + 1) % 8);
      setCurrentPathway((state) => (state + 1) % 8);
    }, 5000);
    return () => clearTimeout(timer);
  }, [index]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container className={`mt-5 pt-5 d-flex flex-column ${large ? "justify-content-center align-items-center" : ""}`}>
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("ourServices")}</b>
          </div>
          <div className="page-header my-3">{service?.title}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            {t("Your needs, our expertise. Find the perfect solution.")}
          </div>
          <div
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param2}/plan`)}
            >
              {t("process")}
            </Button>
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "ms-4" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container className="d-flex flex-column">
        <Row className={`mt-5 pt-5`}>
          <Col lg={7}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: 80,
                height: 80,
                border: "12px solid #F9F5FF",
                borderRadius: 40,
                backgroundColor: "#F4EBFF",
              }}
            >
              <Image src={zap} alt="zap" style={{ width: 40, height: 40 }} />
            </div>
            <h4 className="my-3">
              {t("Unlocking UAE Freezone Business Setup")}
            </h4>
            <div className="subtitle-400 text-secondary mb-3">
              {t("freezoneText")}
            </div>
          </Col>
          <Col lg={1}></Col>
          <Col lg={4}>
            <Card className="mb-4" border="primary" style={{ borderRadius: 15, boxShadow: "8px 8px #2e3192" }}>
              <GetInTouchFormSelect />
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bg-tertiary">
        <Container>
          <div className="mt-5 pt-5 w-75">
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("stepsAndRequirements")}</b>
            </div>
            <h3 className="my-2">{t("yourPathwayToBusinessSuccess")}</h3>
            <div className="subtitle-400 text-secondary mb-5">
              {t("exploreStepsAndRequirementsText")}
            </div>
          </div>
          <Row className="row-cols-4">
            <Col sm={6} className="">
              {pathways.map((pathway, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="p-3 mb-3"
                      style={{
                        borderLeft: currentPathway === index ? "4px solid #2E3192" : "4px solid #F2F4F7",
                      }}
                      onClick={() => setCurrentPathway(index)}
                    >
                      <div className="subtitle mb-2">{pathway.title}</div>
                      <div className="font-size16 text-secondary">{pathway.description}</div>
                    </div>
                    {currentPathway === index && !large && pathways[currentPathway].image}
                  </>
                )
              })}
            </Col>
            {large && <Col sm={6}>
              <div className="sticky">
                {pathways[currentPathway].image}
              </div>
            </Col>}
          </Row>
        </Container>
      </Container>
      <Container>
        <div className="mt-5 pt-5 w-75">
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("services")}</b>
          </div>
          <h3 className="my-2">
            {t("Comprehensive Services for Freezone Business Setup")}
          </h3>
          <div className="subtitle-400 text-secondary mb-5">
            {t("comprehensiveServicesTextForFBS")}
          </div>
        </div>
        <Row className={`mb-5 ${large ? "row-cols-3" : "row-cols-1"}`}>
          {topReasons.map((reason) => {
            return (
              <Col className="mb-5">
                <Card
                  className="p-4 d-flex justify-content-between bg-tertiary border-0"
                  style={{ height: 280 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      width: 48,
                      height: 48,
                      border: "8px solid #F9F5FF",
                      borderRadius: 24,
                      backgroundColor: "#F4EBFF",
                    }}
                  >
                    <Image
                      src={reason.icon}
                      alt={reason.title}
                      style={{ width: 24, height: 24 }}
                    />
                  </div>
                  <div>
                    <div className="subtitle mb-2">{reason.title}</div>
                    <div className="font-size16 text-secondary">
                      {reason.description}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container fluid className="bg-tertiary py-5 mb-5">
        <Container className="p-0 d-flex flex-column justify-content-center align-items-center">
          <div className="">
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("documents")}</b>
            </div>
            <h3 className="my-2">{t("processDocuments")}</h3>
            <div className="subtitle-400 text-secondary mb-5">
              {t("letsStartWithDocumentation")}
            </div>
          </div>
          {plans.map((plan) => {
            return (
              <PlanCard
                plan={plan}
                reqDocumentTypes={reqDocumentTypes}
                serviceSlug={param2}
                createSession={createSession}
              />
            );
          })}
        </Container>
      </Container>
      <ChatWithProfessionals />
      <FAQ />
      <BoostBusiness />
      <Subscribe />
    </>
  );
};

export default FreeZoneBusinessSetup;
