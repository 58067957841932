import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Subscribe, BoostBusiness, FAQ, ChatWithProfessionals, PlanCard } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";
import { whySKVforVisaService } from "assets";
import Flags from "country-flag-icons/react/3x2";
import { FaCheck } from "react-icons/fa6";


const SchengenVisaService = () => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });

  const FlagIcon = ({ code }) => {
    const Flag = Flags[code];

    return (
      <Flag
        className="text-black"
        style={{ height: 40 }}
      />
    );
  };

  const countries = [
    {
      id: 1,
      flag: <FlagIcon code="AT" />,
      name: "Austria",
      memberOfEu: true,
    },
    {
      id: 2,
      flag: <FlagIcon code="BE" />,
      name: "Belgium",
      memberOfEu: true,
    },
    {
      id: 3,
      flag: <FlagIcon code="CZ" />,
      name: "Czech Republic",
      memberOfEu: true,
    },
    {
      id: 4,
      flag: <FlagIcon code="DK" />,
      name: "Denmark",
      memberOfEu: true,
    },
    {
      id: 5,
      flag: <FlagIcon code="EE" />,
      name: "Estonia",
      memberOfEu: true,
    },
    {
      id: 6,
      flag: <FlagIcon code="FI" />,
      name: "Finland",
      memberOfEu: true,
    },
    {
      id: 7,
      flag: <FlagIcon code="FR" />,
      name: "France",
      memberOfEu: true,
    },
    {
      id: 8,
      flag: <FlagIcon code="DE" />,
      name: "Germany",
      memberOfEu: true,
    },
    {
      id: 9,
      flag: <FlagIcon code="GR" />,
      name: "Greece",
      memberOfEu: true,
    },
    {
      id: 10,
      flag: <FlagIcon code="HU" />,
      name: "Hungary",
      memberOfEu: true,
    },
    {
      id: 11,
      flag: <FlagIcon code="IS" />,
      name: "Iceland",
      memberOfEu: false,
    },
    {
      id: 12,
      flag: <FlagIcon code="IT" />,
      name: "Italy",
      memberOfEu: true,
    },
    {
      id: 13,
      flag: <FlagIcon code="LV" />,
      name: "Latvia",
      memberOfEu: true,
    },
    {
      id: 14,
      flag: <FlagIcon code="LI" />,
      name: "Liechtenstein",
      memberOfEu: false,
    },
    {
      id: 15,
      flag: <FlagIcon code="LT" />,
      name: "Lithuania",
      memberOfEu: true,
    },
    {
      id: 16,
      flag: <FlagIcon code="LU" />,
      name: "Luxembourg",
      memberOfEu: true,
    },
    {
      id: 17,
      flag: <FlagIcon code="MT" />,
      name: "Malta",
      memberOfEu: true,
    },
    {
      id: 18,
      flag: <FlagIcon code="NL" />,
      name: "Netherlands",
      memberOfEu: true,
    },
    {
      id: 19,
      flag: <FlagIcon code="NO" />,
      name: "Norway",
      memberOfEu: false,
    },
    {
      id: 20,
      flag: <FlagIcon code="PL" />,
      name: "Poland",
      memberOfEu: true,
    },
    {
      id: 21,
      flag: <FlagIcon code="PT" />,
      name: "Portugal",
      memberOfEu: true,
    },
    {
      id: 22,
      flag: <FlagIcon code="SK" />,
      name: "Slovakia",
      memberOfEu: true,
    },
    {
      id: 23,
      flag: <FlagIcon code="SI" />,
      name: "Slovenia",
      memberOfEu: true,
    },
    {
      id: 24,
      flag: <FlagIcon code="ES" />,
      name: "Spain",
      memberOfEu: true,
    },
    {
      id: 25,
      flag: <FlagIcon code="SE" />,
      name: "Sweden",
      memberOfEu: true,
    },
    {
      id: 26,
      flag: <FlagIcon code="CH" />,
      name: "Switzerland",
      memberOfEu: false,
    },
  ];

  const visaTypes = [
    {
      id: 1,
      title: "Single-Entry Visit",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Processing Time</b>
            <span>Min. 15 Business Days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Entry</b>
            <span>Once</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Valid for entry within</b>
            <span>180 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Stay Period</b>
            <span>Upto 90 days from date of entry</span>
          </div>
        </div>
      </div>,
    },
    {
      id: 2,
      title: "Double-Entry Visit",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Processing Time</b>
            <span>Min. 15 Business Days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Entry</b>
            <span>Twice</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Valid for entry within</b>
            <span>180 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Stay Period</b>
            <span>Upto 90 days from date of entry</span>
          </div>
        </div>
      </div>,
    },
    {
      id: 3,
      title: "Multiple-Entry Visit",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Processing Time</b>
            <span>Min. 15 Business Days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Entry</b>
            <span>Multiple</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Valid for entry within</b>
            <span>180 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Stay Period</b>
            <span>Upto 90 days from date of entry</span>
          </div>
        </div>
      </div>,
    }
  ];

  const visaRequirements = [
    {
      id: 1,
      title: "Visa Application Form",
      description: "Completed and signed.",
    },
    {
      id: 2,
      title: "Passport",
      description: "Must be valid for at least three months after the intended departure from the Schengen Area and issued within the last 10 years.",
    },
    {
      id: 3,
      title: "Photographs",
      description: "Recent passport-size photos that meet visa requirements.",
    },
    {
      id: 4,
      title: "Travel Itinerary",
      description: "Proof of round-trip reservation or itinerary.",
    },
    {
      id: 5,
      title: "Travel Insurance",
      description: "Coverage of at least €30,000 for medical emergencies, valid in all Schengen countries.",
    },
    {
      id: 6,
      title: "Proof of Accommodation",
      description: "Hotel reservations, rental agreements, or an invitation from a host.",
    },
    {
      id: 7,
      title: "Proof of Financial Means",
      description: "Bank statements or sponsorship letter showing you have sufficient funds for your stay.",
    },
    {
      id: 8,
      title: "Purpose of Visit",
      description: "Supporting documents based on purpose of travel, such as business invitations, conference registrations, or tourism plans.",
    },
  ]

  const whySKV = [
    {
      id: 1,
      title: t("persionalizedConsultaion"),
      description: t("persionalizedConsultaionText"),
    },
    {
      id: 2,
      title: t("streamlinedProcess"),
      description: "We manage all aspects of the Schengen visa application process, utilizing our in-depth knowledge of EU immigration regulations to fast-track approvals and reduce delays.",
    },
    {
      id: 3,
      title: t("complianceAssurance"),
      description: "SKV ensures full compliance with visa regulations and documentation standards, minimizing risks and enhancing the success rate of our clients' Schengen visa applications.",
    },
  ]

  const faqs = [
    {
      id: 1,
      question: "Why is the U.K. not in the Schengen Area?",
      answer: "The United Kingdom was never a member of the Schengen Agreement, as it secured an opt-out that other countries did not. Similarly, while Ireland is in the EU it is not in Schengen."
    },
    {
      id: 2,
      question: "Why are non-EU countries in the Schengen Area?",
      answer: "Several countries in the European Economic Area or European Free Trade Association have chosen to join the Schengen Area because of their strong connections with the EU."
    },
    {
      id: 3,
      question: "What is the difference between Schengen and the EU?",
      answer: "The EU is a political and economic union with its own parliament, political systems, and governance, while the Schengen Agreement is a treaty that facilitates the free movement of people between member countries."
    },
    {
      id: 4,
      question: "Do I always need a visa to travel to the Schengen Area?",
      answer: "Some countries, such as the U.K. and the U.S., have reciprocal visa-free travel agreements with Schengen states. However, starting from the end of 2022, travelers from these countries will be required to apply for ETIAS authorization—an electronic document designed to streamline background checks and collect biometric data."
    },
  ]

  const [service, setService] = useState(null);
  const [plans, setPlans] = useState([]);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchService = async () => {
    const response = await actions.getService(param2);
    if (response) {
      setService(response);
    }
  };

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param2);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param2);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      dispatch(actions.setSessionId(response.id));
      navigate(`/services/${param2}/plan`);
    }
  };

  useEffect(() => {
    if (param2) {
      fetchService();
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param2]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("visaServices")}</b>
          </div>
          <div className="page-header my-3">{service?.title}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            {t("Your needs, our expertise. Find the perfect solution.")}
          </div>
          <div
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param2}/plan`)}
            >
              {t("process")}
            </Button>
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "ms-4" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container
        className="my-5 d-flex justify-content-center align-items-center"
        style={{ minHeight: "50vh" }}
      >
        <Row>
          <Col sm={7} className="d-flex flex-column">
            <h3 className="mb-2">
              What is the Schengen Agreement?
            </h3>
            <div className="subtitle-400 text-secondary d-flex flex-column">
              <span>
                The <b>Schengen Agreement</b> was signed in 1985 and led to the creation of the Schengen Area, where
                internal border checks between participating countries have been largely abolished. This allows
                for free and unrestricted movement of people between the member countries, treating the Schengen
                Area almost as a single country for travel purposes.
              </span>
              <span>
                The Agreement was initially signed by five European countries and has since expanded to include
                27 nations. It was incorporated into European Union (EU) law with the 1997 Amsterdam Treaty,
                though not all Schengen countries are EU members, and not all EU members are part of the Schengen
                Area.
              </span>
            </div>
          </Col>
          <Col sm={5}>
            <div className="d-flex justify-content-start align-items-center">
              <Image className="w-100" src={service?.service_image} style={{ borderRadius: 20 }} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="mt-5">
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("schengenVisa")}</b>
          </div>
          <h3 className="my-2">
            What is a Schengen Visa?
          </h3>
          <div className="subtitle-400 text-secondary mb-1">
            The Schengen Visa is a short-term visa that allows its holder to travel freely within the Schengen Area,
            which comprises 27 European countries that have abolished internal borders for unrestricted movement of
            people. The Schengen Area was established in 1995 by the Schengen Agreement, and it aims to facilitate
            the free flow of people and goods within the member states, promoting economic cooperation and security.
          </div>
          <div className="subtitle-400 text-secondary mb-4">
            As of 2024, the Schengen Area includes the following 27 countries:
          </div>
        </div>
        <Row className={`mb-5 ${large ? "row-cols-5" : medium ? "row-cols-3" : "row-cols-2"}`}>
          {countries.map((country) => {
            return (
              <Col className="mb-5">
                <Card
                  key={country.id}
                  className="p-4 d-flex justify-content-between align-items-center bg-tertiary border-0"
                  style={{ height: 240 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      width: 100,
                      height: 100,
                      border: "5px solid #ffffff",
                      borderRadius: 50,
                    }}
                  >
                    {country.flag}
                  </div>
                  <div>
                    <div className="subtitle mb-2 text-center">{country.name}</div>
                    <div className="font-size16 text-secondary text-center">
                      {country.memberOfEu ? "Member of EU" : "Not a member of EU"}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container fluid className="py-5 bg-tertiary">
        <Container className="my-5">
          <div>
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("schengenVisa")}</b>
            </div>
            <h3 className="my-2">
              Choose your Visa Type
            </h3>
            <div className="subtitle-400 text-secondary mb-4">
              There are several categories of Schengen visas based on the purpose and duration of the stay:
            </div>
          </div>
          <div>
            <h4 className="mb-2">Uniform Schengen Visa (USV)</h4>
            <div className="subtitle-400 text-secondary">
              This visa allows entry into any of the Schengen member countries for a period of up to
              90 days within any 180-day period for purposes like tourism, business, or family
              visits. It has three main types:
            </div>
            <Row className={`my-4 d-flex justify-content-center ${large ? "row-cols-4" : "row-cols-1"}`}>
              {visaTypes.map((type) => {
                return (
                  <Col className={`mb-4 d-flex justify-content-center align-items-center`}>
                    <Card
                      className="p-4 border-0 d-flex justify-content-between"
                      style={{
                        minWidth: 300,
                        minHeight: 500,
                        borderRadius: 20,
                        background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)"
                      }}
                    >
                      <h3 className="text-primary text-center">
                        {type.title}
                      </h3>
                      <div className="bg-primary" style={{ height: 3, borderRadius: 2 }} />
                      <div className="font-size16 text-secondary">
                        {type.inclusive}
                      </div>
                      <Button
                        className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"}`}
                        style={{
                          paddingLeft: 30,
                          paddingRight: 30,
                          borderRadius: 15,
                          height: 50,
                        }}
                      // onClick={() => targetRef.current?.scrollIntoView({ behavior: "smooth" })}
                      >
                        {t("process")}
                      </Button>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
          <h4 className="mb-2"> Limited Territorial Validity Visa (LTV)</h4>
          <div className="subtitle-400 text-secondary mb-4">
            This visa allows the holder to travel only within the specified Schengen states.
            The visa does not permit entry to other Schengen countries not specified on the visa.
          </div>
          <h4 className="mb-2"> National Visa (Category D)</h4>
          <div className="subtitle-400 text-secondary">
            This type of visa is issued for specific purposes such as studying, working, or
            residing in one of the Schengen countries for a longer duration. It can be a
            single-entry visa or a multi-entry visa, depending on the circumstances.
          </div>
        </Container>
      </Container>
      <Container fluid className="py-5">
        <Container className="">
          <div className="mt-5">
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("schengenVisa")}</b>
            </div>
            <h3 className="my-2">
              {t("schengenVisa")} : Eligibility and {t("applicationProcess")}
            </h3>
            <div className="subtitle-400 text-secondary mb-5">
              To determine your eligibility for a Schengen Visa, it's essential to know
              that the purpose of travel and the country of origin of each individuals matter.&nbsp;
              <a href="/">SKV Business</a> provides services to help you obtain the Schengen Visa,
              guiding you through the requirements based on your eligibility and the desired
              travel duration:
            </div>
          </div>
          <span className="page-header">01</span>
          <span className="subtitle mb-2 text-bold"> {t("eligibilityCriteria")}</span>
          <div className="subtitle-400 text-secondary">
            Citizens of certain countries are required to obtain a Schengen Visa before entering
            the Schengen Area. The eligibility criteria may vary depending on the applicant's
            nationality, purpose of travel, and other factors.
          </div>
          <div className="subtitle-400 text-secondary">
          </div>
          <span className="page-header">02</span>
          <span className="subtitle mb-2 text-bold"> {t("documentationRequirements")}</span>
          <div className="subtitle-400 text-secondary mb-2"> {t("documentationRequirementsText")}</div>
          <Row className={`mt-4 ${large ? "row-cols-4" : medium ? "row-cols-2" : "row-cols-1"}`}>
            {visaRequirements.map((requirement) => {
              return (
                <Col className={`d-flex justify-content-center align-items-center`}>
                  <Card
                    key={requirement.id}
                    className="mb-3 p-4 border-0 bg-primary"
                    style={{
                      width: 300,
                      minHeight: large ? 190 : medium ? 200 : "",
                      borderRadius: 20,
                      background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)",
                    }}
                  >
                    <h4 className="text-primary">
                      {requirement.title}:
                    </h4>
                    <div className="body-text1">{requirement.description}</div>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <span className="page-header">03</span>
          <span className="subtitle mb-2 text-bold"> Application Process</span>
          <div className="subtitle-400 text-secondary mb-2">
            Using the above mentioned documents, we at SKV Business Services, apply at the
            consulate or visa application center of the country where you plan to spend the
            most time during your trip. If no single country is the primary destination, we
            apply at the consulate of the country where you will first enter.
            In addition to this, if you have not applied for a Schengen Visa in the last five
            years, you may be required to submit fingerprints and a digital photo.
          </div>
          <br />
        </Container>
      </Container>
      <Container fluid className="py-5 bg-tertiary">
        <Container className="py-5">
          <div>
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("services")}</b>
            </div>
            <h3 className="my-2">
              Why Choose SKV for Schengen Visa Services?
            </h3>
            <Row className="mt-4">
              <Col sm={6} className="d-flex justify-content-center align-items-center">
                <div className="subtitle-400 text-secondary mb-5">
                  SKV is a trusted provider of comprehensive Schengen visa services, offering expert
                  guidance and support to individuals seeking to travel or reside temporarily within
                  the Schengen Area. Our team of experienced professionals assists clients through
                  every step of the application process, from the initial consultation to document
                  preparation and submission, ensuring a smooth and stress-free experience.
                </div>
              </Col>
              <Col sm={6} className="d-flex justify-content-end align-items-center">
                <Image className="w-80" src={whySKVforVisaService} alt="Why Choose SKV for Schengen Visa" style={{ borderRadius: 20 }} />
              </Col>
            </Row>
          </div>
          <div className="subtitle mb-2 text-bold">
            {t("benefitOfChoosingSKVVisaService")}
          </div>
          {whySKV.map((why) => {
            return (
              <>
                <div className="subtitle mb-2 text-bold">
                  {`${why.id}. ${why.title}`}
                </div>
                <div className="mb-2"> {why.description}</div>
              </>
            )
          })}
        </Container>
      </Container>
      <Container fluid className="my-5 py-5 d-flex flex-column justify-content-center align-items-center">
        <div>
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("documents")}</b>
          </div>
          <h3 className="my-2">{t("processDocuments")}</h3>
          <div className="subtitle-400 text-secondary mb-5">
            {t("letsStartWithDocumentation")}
          </div>
        </div>
        <Container className="d-flex justify-content-center align-items-center">
          {plans.map((plan) => {
            return (
              <PlanCard
                plan={plan}
                reqDocumentTypes={reqDocumentTypes}
                serviceSlug={param2}
                createSession={createSession}
              />
            );
          })}
        </Container>
      </Container>
      <ChatWithProfessionals />
      <FAQ inputFaqs={faqs} />
      <BoostBusiness />
      <Subscribe />
    </>
  );
};

export default SchengenVisaService;