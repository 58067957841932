import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Subscribe, BoostBusiness, FAQ, ChatWithProfessionals, PlanCard } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { whySKVforVisaService } from "assets";
import { t } from "i18next";

import { FcMultipleInputs } from "react-icons/fc";
import { FaUserClock, FaBusinessTime } from "react-icons/fa6";
import { GrGateway } from "react-icons/gr";
import { MdSafetyCheck } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";

const GoldenVisaService = () => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const keyFeaturesAndBenefits = [
    {
      id: 1,
      icon: <FaUserClock size={40} color="#1565c0" />,
      title: t("longTermResidency"),
      description: t("longTermResidencyText"),
    },
    {
      id: 2,
      icon: <FaBusinessTime size={40} color="#1565c0" />,
      title: t("freedomToWorkStudy"),
      description: t("freedomToWorkStudyText"),
    },
    {
      id: 3,
      icon: <FcMultipleInputs size={40} color="#1565c0" />,
      title: t("multiLevelPermit"),
      description: t("multiLevelPermitText"),
    },
    {
      id: 4,
      icon: <GrGateway size={40} color="#1565c0" />,
      title: t("gatewayToOtherCountries"),
      description: t("gatewayToOtherCountriesText"),
    },
    {
      id: 5,
      icon: <MdSafetyCheck size={40} color="#1565c0" />,
      title: t("safeSecureAndHighStandard"),
      description: t("safeSecureAndHighStandardText"),
    },
    {
      id: 6,
      icon: <GiReceiveMoney size={40} color="#1565c0" />,
      title: t("favorableTaxRegime"),
      description: t("favorableTaxRegimeText"),
    },
  ];

  const eligibilityCriteria = [
    { id: 1, title: "Real Estate Investors" },
    { id: 2, title: "Entrepreneurs" },
    { id: 3, title: "Exceptional Specialized Talents" },
    { id: 4, title: "Top-performing Students" },
  ]

  const whySKV = [
    {
      id: 1,
      title: t("persionalizedConsultaion"),
      description: t("persionalizedConsultaionText"),
    },
    {
      id: 2,
      title: t("streamlinedProcess"),
      description: t("streamlinedProcessText"),
    },
    {
      id: 3,
      title: t("complianceAssurance"),
      description: t("complianceAssuranceText"),
    },
  ]

  const faqs = [
    {
      id: 1,
      question: "What is UAE Residence by Investment?",
      answer: "The United Arab Emirates Residence by Investment Program provides residency to individuals and their families in exchange for substantial economic contributions to the country."
    },
    {
      id: 2,
      question: "What are the investment options for UAE residency?",
      answer: "Investment options include purchasing property, acquiring property through loans from select local banks, or buying off-plan properties from approved real estate companies."
    },
    {
      id: 3,
      question: "What is the minimum investment needed for UAE residency?",
      answer: "The minimum investment for real estate purchases begins at AED 2 million."
    },
    {
      id: 4,
      question: "Can family members be added to a UAE Residence by Investment application?",
      answer: "Yes, the main applicant can include their spouse and dependent children in the application."
    },
    {
      id: 5,
      question: "What documents are needed for a UAE Residence by Investment application?",
      answer: "The required documents include a valid passport, proof of investment or contribution, a clean criminal record, and additional supporting materials.",
    },
    {
      id: 6,
      question: "Can you obtain UAE citizenship through residence by investment?",
      answer: "No, acquiring UAE citizenship involves a separate and more rigorous process.",
    },
  ]

  const [service, setService] = useState(null);
  const [plans, setPlans] = useState([]);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchService = async () => {
    const response = await actions.getService(param2);
    if (response) {
      setService(response);
    }
  };

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param2);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param2);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      dispatch(actions.setSessionId(response.id));
      navigate(`/services/${param2}/plan`);
    }
  };

  useEffect(() => {
    if (param2) {
      fetchService();
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param2]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("visaServices")}</b>
          </div>
          <div className="page-header my-3">{service?.title}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            {t("Your needs, our expertise. Find the perfect solution.")}
          </div>
          <div
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param2}/plan`)}
            >
              {t("process")}
            </Button>
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "ms-4" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container
        className="my-5 d-flex justify-content-center align-items-center"
        style={{ minHeight: "50vh" }}
      >
        <Row>
          <Col sm={6}>
            <div className="d-flex justify-content-start align-items-center w-100">
              <Image src={service?.service_image} style={{ borderRadius: 20 }} />
            </div>
          </Col>
          <Col sm={6} className="d-flex flex-column">
            <h3 className="mb-2">
              Obtain the Prestigious UAE Golden Visa
            </h3>
            <div className="subtitle-400 text-secondary d-flex flex-column">
              <span>{t("goldenVisaPara1")}</span>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="mt-5 w-75">
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("services")}</b>
          </div>
          <h3 className="my-2">
            {t("goldenVisa")} : Key Features and Benefits
          </h3>
          <div className="subtitle-400 text-secondary mb-5">
            The Golden Visa is designed to create a welcoming environment for those seeking to establish the UAE as their base of operations. It offers investors and entrepreneurs the opportunity to easily reside in the UAE, along with several additional benefits:
          </div>
        </div>
        <Row className={`mb-5 ${large ? "row-cols-3" : "row-cols-1"}`}>
          {keyFeaturesAndBenefits.map((reason) => {
            return (
              <Col className="mb-5">
                <Card
                  className="p-4 d-flex justify-content-between bg-tertiary border-0"
                  style={{ height: 350 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      width: 80,
                      height: 80,
                      border: "10px solid #ffffff",
                      borderRadius: 40,
                    }}
                  >
                    {reason.icon}
                  </div>
                  <div>
                    <div className="subtitle mb-2">{reason.title}</div>
                    <div className="font-size16 text-secondary">
                      {reason.description}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container fluid className="py-5 bg-tertiary" style={{}}>
        <Container className="">
          <div className="mt-5">
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("services")}</b>
            </div>
            <h3 className="my-2">
              {t("goldenVisa")} : Eligibility and {t("applicationProcess")}
            </h3>
            <div className="subtitle-400 text-secondary mb-5">
              To determine your eligibility for a Dubai Golden Visa, it's essential to know that it is only available to specific categories of individuals.&nbsp;
              <a href="/">SKV Business</a> provides services to help you obtain the Golden Visa, guiding you through the requirements based on your eligibility and the desired residency duration:
            </div>
          </div>
          <div>
            <span className="page-header">01</span>
            <span className="subtitle mb-2 text-bold"> {t("eligibilityCriteria")}</span>
            <div className="subtitle-400 text-secondary"> {t("eligibilityCriteriaText")}</div>
            <Row className={`my-4 ${large ? "row-cols-4" : "row-cols-1"}`}>
              {eligibilityCriteria.map((criteria) => {
                return (
                  <Col className={`d-flex justify-content-center align-items-center`}>
                    <Card
                      className="p-4 border-0 d-flex justify-content-center align-items-center text-center bg-primary"
                      style={{ width: 300, minHeight: 120, borderRadius: 20, background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}
                    >
                      <h4 className="text-primary">
                        {criteria.title}
                      </h4>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <div className="subtitle-400 text-secondary">
              For real estate investors one can secure a 10-year renewable residence visa by purchasing one of the following:
              <ul>
                <li>A property valued at a minimum of AED 2 million (around USD 550,000).</li>
                <li>A property valued at a minimum of AED 2 million (around USD 550,000) with a loan from specific local banks.</li>
                <li>One or more off-plan properties with a combined value of at least AED 2 million from approved local real estate companies.</li>
              </ul>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                className={`text-white primary-button border-0 poppins-600 ${large ? "" : "w-100"}`}
                style={{
                  paddingLeft: 40,
                  paddingRight: 40,
                  borderRadius: 30,
                  height: 60,
                }}
                onClick={() => navigate("/get-in-touch")}
              >
                Check Eligibility
              </Button>
            </div>
          </div>
          <span className="page-header">02</span>
          <span className="subtitle mb-2 text-bold"> {t("documentationRequirements")}</span>
          <div className="subtitle-400 text-secondary mb-2"> {t("documentationRequirementsText")}</div>
          <span className="page-header">03</span>
          <span className="subtitle mb-2 text-bold"> {t("grantingResidenceVisa")}</span>
          <div className="subtitle-400 text-secondary mb-2"> {t("grantingResidenceVisaText")}</div>
          <br />
        </Container>
      </Container>
      <Container className="my-5">
        <div className="mt-5">
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("services")}</b>
          </div>
          <h3 className="my-2">
            {t("whyChooseSKVForGoldenVisa")}
          </h3>
          <Row className="mt-4">
            <Col sm={6} className="d-flex justify-content-center align-items-center">
              <div className="subtitle-400 text-secondary mb-5">
                {t("whyChooseSKVForGoldenVisaText")}
              </div>
            </Col>
            <Col sm={6} className="d-flex justify-content-end align-items-center">
              <Image className="w-80" src={whySKVforVisaService} alt="Why Choose SKV for Golden Visa" style={{ borderRadius: 20 }} />
            </Col>
          </Row>
        </div>
        <div className="subtitle mb-2 text-bold">
          {t("benefitOfChoosingSKVVisaService")}
        </div>
        {whySKV.map((why) => {
          return (
            <>
              <div className="subtitle mb-2 text-bold">
                {`${why.id}. ${why.title}`}
              </div>
              <div className="mb-2"> {why.description}</div>
            </>
          )
        })}
      </Container>
      <Container fluid className="my-5 bg-tertiary d-flex flex-column justify-content-center align-items-center">
        <div className="mt-5">
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("documents")}</b>
          </div>
          <h3 className="my-2">{t("processDocuments")}</h3>
          <div className="subtitle-400 text-secondary mb-5">
            {t("letsStartWithDocumentation")}
          </div>
        </div>
        {plans.map((plan) => {
          return (
            <PlanCard
              plan={plan}
              reqDocumentTypes={reqDocumentTypes}
              serviceSlug={param2}
              createSession={createSession}
            />
          );
        })}
      </Container>
      <ChatWithProfessionals />
      <FAQ inputFaqs={faqs} />
      <BoostBusiness />
      <Subscribe />
    </>
  );
};

export default GoldenVisaService;