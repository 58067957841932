import React from "react";
import { proServiceHelp, proServiceMain } from "assets";
import { Col, Container, Image, Row, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { IoIosPaper } from "react-icons/io";
import { FaMoneyBillTransfer, FaReceipt } from "react-icons/fa6";
import { IoDocuments } from "react-icons/io5";
import { GiReceiveMoney } from "react-icons/gi";

const CorporateTax = () => {
    const { t } = useTranslation();
    const large = useMediaQuery({ query: "(min-width: 992px)" });

    const requiredDocuments = [
        {
            id: 1,
            icon: <IoIosPaper size={40} color="#1565c0" />,
            description: "Financial statements for calculation of taxable income",
        },
        {
            id: 2,
            icon: <FaReceipt size={40} color="#1565c0" />,
            description: "Receipts for deductions to be claimed",
        },
        {
            id: 3,
            icon: <IoIosPaper size={40} color="#1565c0" />,
            description: "Financial statements for estimating exempt income",
        },
        {
            id: 4,
            icon: <IoDocuments size={40} color="#1565c0" />,
            description: "Records of exemption status",
        },
        {
            id: 5,
            icon: <GiReceiveMoney size={40} color="#1565c0" />,
            description: "Business loan documents signifying interest paid",
        },
        {
            id: 6,
            icon: <FaMoneyBillTransfer size={40} color="#1565c0" />,
            description: "Transfer pricing records",
        },
        {
            id: 7,
            icon: <IoDocuments size={40} color="#1565c0" />,
            description: "Records of foreign tax paid if any",
        },
    ];

    return (
        <>
            <Container>
                <div className={`pt-5 ${large ? "w-75" : ""}`}>
                    <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
                        <b>{t("corporateTaxRegistration")}</b>
                    </div>
                    <div className="page-header my-2">
                        Documents Required for Corporate TAX Filing
                    </div>
                    <div className="subtitle-400 text-secondary mb-5">
                        Following is the list of documents required for Corporate Tax filing:
                    </div>
                </div>
                <Row className={`pb-5 ${large ? "row-cols-3" : "row-cols-1"}`}>
                    {requiredDocuments.map((item) => {
                        return (
                            <Col className="mb-5">
                                <Card
                                    className="p-4 d-flex justify-content-between border-0"
                                    style={{ height: 200, background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}
                                >
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            width: 80,
                                            height: 80,
                                            border: "10px solid #ffffff",
                                            borderRadius: 40,
                                        }}
                                    >
                                        {item.icon}
                                    </div>
                                    <div>
                                        <div className="subtitle mb-2">{item.description}</div>
                                    </div>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </>
    )
}

export default CorporateTax;